<template>
  <v-container>
    <v-card
      v-if="loaded"
    >
      <v-card-text>
        <p class="display-1 text--primary">
          <!-- eslint-disable max-len -->
          <v-icon
            x-large
            :color="setStatusColor(oltInterfaceTable.olt.enable, oltInterfaceTable.olt.uptime.uptime)"
          >
            mdi-switch
          </v-icon>
          {{ oltInterfaceTable.olt.name }} ({{ oltInterfaceTable.olt.ip }}) -
          {{ oltInterfaceTable.ifPosition.rack }}/{{ oltInterfaceTable.ifPosition.shelf }}/{{ oltInterfaceTable.ifPosition.slot }}/{{ oltInterfaceTable.ifPosition.port }}&nbsp;
          ({{ oltInterfaceTable.ifName.ifName }})
        </p>
        <v-chip
          class="ma-2"
          color="primary"
        >
          <v-icon left>
            mdi-domain
          </v-icon>
          {{ oltInterfaceTable.olt.officeSector.name }}
        </v-chip>
        <br>
        <b>Brand:</b> {{ oltInterfaceTable.olt.oltType.oltBrand.name }}<br>
        <b>Type:</b> {{ oltInterfaceTable.olt.oltType.name }}<br>
        <b>Project:</b> {{ oltInterfaceTable.olt.oltProject.name }}<br>
        <b>Uptime:</b> {{ calSysUpTime(oltInterfaceTable.olt.uptime.uptime) }}
        (Updated at: {{ formatDate(oltInterfaceTable.olt.uptime.updatedAt) }} )<br>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import axios from 'axios'
import moment from 'moment'

moment.locale('th')

export default {
  name: 'OltInterfaceTableDetailCard',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {
      oltInterfaceTable: '',
      loaded: false,
    }
  },
  computed: {

  },
  watch: {
    options: {
      handler() {
        // this.getDataFromAPI()
        this.showOltInterfaceTable()
      },
      deep: true,
    },
    search: {
      handler() {
        // this.getDataFromAPI()
        this.showOltInterfaceTable()
      },
      deep: true,
    },
  },
  mounted() {
    this.loaded = false
    this.showOltInterfaceTable()
  },
  created() {

  },
  methods: {
    showOltInterfaceTable() {
      this.loaded = false
      axios.get(`/oltInterfaceTable/${this.id}`)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.oltInterfaceTable = response.data
          this.loaded = true
        })
        .catch((e) => {
          this.errors.push(e)
          // this.loaded = false
        })
    },
    setStatusColor(enable, uptime) {
      if ((enable) && (uptime !== null)) return 'success'
      if ((enable) && (uptime === null)) return 'error'
      return ''
    },
    calSysUpTime(upTime) {
      const uptimeSecond = upTime / 100
      const day = Math.floor(uptimeSecond / (86400))
      const hour = Math.floor((uptimeSecond % 86400) / 3600)
      const minute = Math.floor((uptimeSecond % 3600) / 60)
      const upTimeText = ` ${day} วัน ${hour} ชั่วโมง ${minute} นาที `
      return upTimeText
    },
    formatDate(date) {
      return moment(date).format('LLL')
    },
  },
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
