<template>
  <div class="container">
    <v-card
      v-if="loaded"
    >
      <v-toolbar
        dark
        dense
        flat
        color="primary"
      >
        <v-toolbar-title>Interface Unicast Packet Forwarding Rate</v-toolbar-title>
      </v-toolbar>
      <div>
        <vue-apex-charts
          height="350"
          :options="options"
          :series="series"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'

export default {
  name: 'OltInterfaceTableInterfaceUnicastPacketForwardingRateCardContainer',
  components: { VueApexCharts },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data: () => ({
    loaded: false,
    interval: null,
    hasSeries: false,
    options: {
      chart: {
        id: 'InterfaceUnicastPacketForwardingRateLineChartContainer',
        // animations: {
        //   enabled: true,
        //   easing: 'linear',
        //   speed: 500,
        //   animateGradually: {
        //     enabled: true,
        //     delay: 150,
        //   },
        //   dynamicAnimation: {
        //     enabled: true,
        //     speed: 350,
        //   },
        // },
        height: 350,
        type: 'area',
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
      },
      colors: ['#4ECDC4', '#C7F464'],
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
        },
      },
      yaxis: {
        labels: {
          formatter(value) {
            if (value === 0) return `${value} pps`
            if (value < 1000) return `${value.toFixed(2)} pps`
            if ((value >= 1000) && (value < 1000000)) return `${(value / 1000).toFixed(2)} Kpps`
            if ((value >= 1000000) && (value < 1000000000)) return `${(value / 1000000).toFixed(2)} Mpps`
            return `${(value / 1000000000).toFixed(2)} Gpps`
          },
        },
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy - HH:mm',
        },
      },
    },
    series: [
      {
        name: 'Interface In Unicast Packet',
        data: null,
      },
      {
        name: 'Interface Out Unicast Packet',
        data: null,
      },
    ],
  }),
  mounted() {
    this.loaded = false
    this.hasSeries = false
    this.getDataFromAPI()
    this.interval = setInterval(() => {
      this.getDataFromAPI()
    }, 60000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    getDataFromAPI() {
      axios.get(`/oltInterfaceTable/${this.id}/oltInterfaceUnicastPacketForwardingRate`)
        .then((response) => {
          const result = response.data
          this.options.xaxis.categories = result.map(a => a.time)
          this.series[0].data = result.map(a => a.mean_ifInUcastPacketForwardingRate)
          this.series[1].data = result.map(a => a.mean_ifOutUcastPacketForwardingRate)
          this.hasSeries = result.length > 0
          this.loaded = true
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
    },
  },
}
</script>
