<template>
  <v-container>
    <olt-interface-table-detail-card-container :id="id" />
    <olt-interface-table-interface-bandwidth-card-container :id="id" />
    <olt-interface-table-interface-broadcast-packet-forwarding-rate-card-container :id="id" />
    <olt-interface-table-interface-multicast-packet-forwarding-rate-card-container :id="id" />
    <olt-interface-table-interface-unicast-packet-forwarding-rate-card-container :id="id" />
  </v-container>
</template>

<script>

// import axios from 'axios'
import moment from 'moment'
import OltInterfaceTableDetailCardContainer from './OltInterfaceTableDetailCardContainer.vue'
import OltInterfaceTableInterfaceBandwidthCardContainer from './OltInterfaceTableInterfaceBandwidthCardContainer.vue'
import OltInterfaceTableInterfaceBroadcastPacketForwardingRateCardContainer from './OltInterfaceTableInterfaceBroadcastPacketForwardingRateCardContainer.vue'
import OltInterfaceTableInterfaceMulticastPacketForwardingRateCardContainer from './OltInterfaceTableInterfaceMulticastPacketForwardingRateCardContainer.vue'
import OltInterfaceTableInterfaceUnicastPacketForwardingRateCardContainer from './OltInterfaceTableInterfaceUnicastPacketForwardingRateCardContainer.vue'

moment.locale('th')

export default {
  name: 'OltInterfaceTrafficDetail',
  components: {
    OltInterfaceTableDetailCardContainer,
    OltInterfaceTableInterfaceBandwidthCardContainer,
    OltInterfaceTableInterfaceBroadcastPacketForwardingRateCardContainer,
    OltInterfaceTableInterfaceMulticastPacketForwardingRateCardContainer,
    OltInterfaceTableInterfaceUnicastPacketForwardingRateCardContainer,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {

    }
  },
  computed: {

  },
  watch: {
    options: {
      handler() {

      },
      deep: true,
    },
    search: {
      handler() {

      },
      deep: true,
    },
  },
  created() {

  },
  methods: {
    setStatusColor(enable, uptime) {
      if ((enable) && (uptime !== null)) return 'success'
      if ((enable) && (uptime === null)) return 'error'
      return ''
    },
    calSysUpTime(upTime) {
      const uptimeSecond = upTime / 100
      const day = Math.floor(uptimeSecond / (86400))
      const hour = Math.floor((uptimeSecond % 86400) / 3600)
      const minute = Math.floor((uptimeSecond % 3600) / 60)
      const upTimeText = ` ${day} วัน ${hour} ชั่วโมง ${minute} นาที `
      return upTimeText
    },
    formatDate(date) {
      return moment(date).format('LLL')
    },
  },
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
